import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { DragDropContext, DropResult, Droppable } from 'react-beautiful-dnd';
import { Column } from './Column/Column';
import { NavBar } from '../../components/NavBar';
import { ColumnContainer, Container, KanbanScrollContainer, Title } from './Dashboard.style';
import {
  ChangeProjectTaskWorkflowStepDocument,
  ChangeProjectTaskWorkflowStepMutation,
  GetKanbanWorkflowStepsByProjectDocument,
  GetKanbanWorkflowStepsByProjectQuery,
  GetProjectKanbanDocument,
  GetProjectKanbanQuery,
  GetProjectTasksByWorkflowStepDocument,
  ProjectTask,
} from '../../generated/graphql';
import { useParams } from 'react-router-dom';
import { Snackbar } from '../../components/Snackbar';
import { LoadingDisplay } from '../../components/LoadingDisplay';

interface Columns {
  [key: string]: { id: string; title: string; tasks: string[] };
}

export interface InitialData {
  tasks: ProjectTask;
  columns: Columns;
  columnOrder: string[];
}

const initialData: InitialData = {
  tasks: {},
  columns: {},
  columnOrder: [],
};

export const Dashboard = () => {
  const [t] = useTranslation('translation');
  const [state, setState] = useState(initialData);
  const { projectId } = useParams<{ projectId: string }>();

  const {
    data: kanbanData,
    loading: kanbanLoading,
    error: kanbanError,
  } = useQuery<GetProjectKanbanQuery>(GetProjectKanbanDocument, {
    variables: {
      projectId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: columnsData,
    loading: columnLoading,
    error: columnError,
  } = useQuery<GetKanbanWorkflowStepsByProjectQuery>(GetKanbanWorkflowStepsByProjectDocument, {
    variables: {
      projectId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const [dropTask, { loading: dropTaskLoading, error: dropTaskError }] =
    useMutation<ChangeProjectTaskWorkflowStepMutation>(ChangeProjectTaskWorkflowStepDocument);

  let content: JSX.Element;
  let errorContent: JSX.Element;

  if (kanbanLoading || dropTaskLoading || columnLoading) {
    content = <LoadingDisplay />;
  }

  const onDragStart = (): void => {
    document.body.style.color = 'orange';
    document.body.style.transition = `background-color .2s ease`;
  };

  const onDragEnd = (result: DropResult): void => {
    document.body.style.color = 'inherit';
    const { destination, source, draggableId } = result;

    if (!destination || !draggableId || !source) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const start = state.columns[source.droppableId];
    const finish = state.columns[destination.droppableId];

    if (start === finish) {
      const newTaskIds = Array.from(start.tasks);
      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);
      const newColumn = {
        ...start,
        tasks: newTaskIds,
      };
      setState({ ...state, columns: { ...state.columns, [newColumn.id]: newColumn } });
      return;
    }

    const startTaskIds = Array.from(start.tasks);
    startTaskIds.splice(source.index, 1);
    const newStart = {
      ...start,
      tasks: startTaskIds,
    };

    const finishTaskIds = Array.from(finish.tasks);
    finishTaskIds.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      tasks: finishTaskIds,
    };
    setState({
      ...state,
      columns: {
        ...state.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    });

    dropTask({
      variables: {
        tasksId: draggableId,
        workflowStepId: destination.droppableId,
        workflowStepOrder: destination.index,
      },
      refetchQueries: [
        {
          query: GetProjectTasksByWorkflowStepDocument,
          variables: {
            projectId,
            workflowStepId: source.droppableId,
            limit: 1000,
            skip: 0,
          },
        },
        {
          query: GetProjectTasksByWorkflowStepDocument,
          variables: {
            projectId,
            workflowStepId: destination.droppableId,
            limit: 1000,
            skip: 0,
          },
        },
      ],
    });
  };

  if (columnsData && kanbanData) {
    const columns = columnsData.getKanbanWorkflowStepsByProject;

    if (columns?.length !== state.columnOrder?.length) {
      const stateColumns = columns.reduce((acc: { [key: string]: any }, obj) => {
        const key = obj.id;
        acc[key] = { id: obj.id, name: obj.name };
        return acc;
      }, {});

      const order = columns.map(step => step.id);
      columns &&
        setState({
          ...state,
          columns: { ...stateColumns },
          columnOrder: [...order],
        });
    }

    content = (
      <Droppable droppableId="all-columns" type="column" direction="horizontal">
        {provided => (
          <ColumnContainer {...provided.droppableProps} ref={provided.innerRef}>
            {state.columnOrder.map((columnId, index) => {
              const column = state.columns[columnId];
              // @ts-ignore
              const tasks = column.tasks?.map(taskId => state.tasks[taskId]);

              return (
                <Column
                  key={column.id}
                  column={column}
                  tasks={tasks}
                  index={index}
                  state={state}
                  setState={setState}
                  projectId={projectId}
                />
              );
            })}
          </ColumnContainer>
        )}
      </Droppable>
    );
  }

  if (kanbanError || columnError || dropTaskError) {
    errorContent = <Snackbar text={''} />;
  }

  return (
    <Container>
      <NavBar />
      <Title>{t('dashboard')}</Title>

      <KanbanScrollContainer>
        <DragDropContext onDragStart={onDragStart} onDragEnd={e => onDragEnd(e)}>
          {content}
        </DragDropContext>
      </KanbanScrollContainer>
      {errorContent}
    </Container>
  );
};
