import React, { useContext, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Header } from '../Header';
import { SideBar } from '../SideBar';
import { NavBar } from '../NavBar';
import { Profile } from '../Profile';
import './Layout.scss';
import { User } from '../../generated/graphql';
import { UserInfoContext } from '../../pages/Main/Main';
import { ClientSidebar } from '../ClientSidebar';
import { Footer } from '../Footer';
import { useLocation } from 'react-router-dom';

interface ILayoutProps {
  children: React.ReactNode;
}

export const Layout = ({ children }: ILayoutProps) => {
  const { pathname } = useLocation();
  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(true);
  const [isProfile, setIsProfile] = useState<boolean>(false);

  const page = pathname.split('/').slice(-1)[0];
  const projectId = pathname.split('/').slice(-2, -1)[0];

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 992px)',
  });
  const isLageDesktop = useMediaQuery({
    query: '(min-width: 1500px)',
  });

  const { role } = useContext<User>(UserInfoContext);

  return (
    <div className="layout bg d-flex" style={{ minHeight: '100vh' }}>
      <header className="header">
        <Header
          isSideBarOpen={isSideBarOpen}
          isProfile={isProfile}
          setIsSideBarOpen={setIsSideBarOpen}
          setIsProfile={setIsProfile}
        />
      </header>

      {role?.id === 3 ? (
        <ClientSidebar isSideBarOpen={isSideBarOpen} setIsSideBarOpen={setIsSideBarOpen} />
      ) : (
        <SideBar isSideBarOpen={isSideBarOpen} setIsSideBarOpen={setIsSideBarOpen} />
      )}

      <div
        style={
          !isDesktopOrLaptop
            ? { width: '100%' }
            : isLageDesktop && isSideBarOpen
            ? { width: 'calc(100% - 292px)' }
            : isDesktopOrLaptop && isSideBarOpen
            ? { width: 'calc(100% - 292px)' }
            : { width: 'calc(100% - 116px)' }
        }
      >
        <div className="empty" />

        {isProfile && <Profile closeLogoutModal={setIsProfile} />}

        {role?.id === 3 && (
          <div className="navbar-container d-none d-lg-flex justify-content-center">
            <NavBar />
          </div>
        )}
        <main className="main h-100">{children}</main>
        {!isDesktopOrLaptop && (
          <>
            <div className="empty-footer" />
            <Footer
              className="footer d-flex justify-content-between align-items-center d-lg-none"
              activeBtn={page}
              projectId={projectId}
            />
          </>
        )}
      </div>
    </div>
  );
};
