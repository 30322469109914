import { makeStyles } from '@material-ui/core';

import { black, primary, red, white } from './colors';

export const useWhiteDictionaryStyles = makeStyles({
  root: {
    margin: 0,
    marginBottom: '0.5rem',
    backgroundColor: `${white}`,

    '& .MuiFilledInput-root': {
      backgroundColor: `${white}`,
    },

    '& label.Mui-focused': {
      color: `${primary}`,
    },
    '& .MuiFilledInput-underline:after': {
      borderBottomColor: `${primary}`,
    },
    '& .MuiFilledInput-underline.Mui-error:after': {
      borderBottomColor: `${red}`,
    },
    '&:after': {
      borderBottom: `2px solid ${primary}`,
    },
    '& label.MuiInputLabel-root': {
      // color: `${primary}`,
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
      color: `${primary}`,
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: `${primary}`,
    },
    '& .MuiInputBase-input.Mui-disabled': {
      color: black,
    },
  },
});
