import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Portal } from 'react-portal';
import { Button } from 'react-bootstrap';
import { FormLoginRegistration } from '../FormLoginRegistration';
import { Snackbar } from '../Snackbar';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { ForgotPasswordDocument, ForgotPasswordMutation } from '../../generated/graphql';
import { LoadingDisplay } from '../LoadingDisplay';
import './ConfirmationModal.scss';

interface ConfirmationModalProps {
  text: string;
  handleSubmit?(e: React.FormEvent<HTMLFormElement> | undefined): void;
  onCancelClick?(): void;
  onClose?(e: boolean): void;
  passwordRecovery?: boolean;
}

interface IInitialValues {
  email: string;
}

const initialValues: IInitialValues = {
  email: '',
};

export const ConfirmationModal = ({ text, handleSubmit, onCancelClick, passwordRecovery }: ConfirmationModalProps) => {
  const [t] = useTranslation('translation');

  const [forgotPassword, { data, loading, error }] = useMutation<ForgotPasswordMutation>(ForgotPasswordDocument);

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      email: Yup.string().email(t('invalid email')).required(t('required')),
    }),

    onSubmit: async values => {
      await forgotPassword({
        variables: {
          email: values.email,
          isFromAD: false,
        },
      });
    },
  });

  if (loading) {
    return <LoadingDisplay />;
  }

  return (
    <Portal>
      <div className="confirmation-modal-container d-flex justify-content-center align-items-center">
        <div className="confirmation-modal d-flex flex-column justify-content-center align-items-center">
          <h2 className="confirmation-modal-text">
            {data?.forgotPassword ? `${t('email sent to')} ${formik.values.email}` : text}
          </h2>
          {handleSubmit && (
            <form onSubmit={handleSubmit}>
              <Button className="form-logout-btn-save" type="submit">
                {t('save')}
              </Button>
              <Button className="form-logout-btn-logout" onClick={() => onCancelClick && onCancelClick()}>
                {t('cancel')}
              </Button>
            </form>
          )}

          {passwordRecovery && !data && (
            <div className="d-flex flex-column align-items-center position-relative">
              <FormLoginRegistration formik={formik} passwordRecovery />
              {error && (
                <Snackbar
                  text={t('such user not exist')}
                  className="authorizationError email-error position-absolute"
                />
              )}
              <Button
                className="password-reminder-link remembered-password-btn position-absolute"
                variant="link"
                href="/login"
              >
                {t('I remembered my password')}
              </Button>
            </div>
          )}
        </div>
      </div>
    </Portal>
  );
};
