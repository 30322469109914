import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import Cookies from 'js-cookie';
import { TOKEN } from '../../core/apollo/client';
import { ResetPasswordDocument, ResetPasswordMutation } from '../../generated/graphql';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Logo } from '../../components/Logo';
import { FormLoginRegistration } from '../../components/FormLoginRegistration';
import { Snackbar } from '../../components/Snackbar';
import { LoadingDisplay } from '../../components/LoadingDisplay';
import { Col, Row } from 'react-bootstrap';
import '../SignIn/SignIn.scss';

interface IInitialValues {
  password: string;
}

const initialValues: IInitialValues = {
  password: '',
};

interface PasswordRecoveryProps {
  token: string;
}

export const PasswordRecovery = ({ token }: PasswordRecoveryProps) => {
  const [t] = useTranslation('translation');
  const [resetPassword, { data, loading, error }] = useMutation<ResetPasswordMutation>(ResetPasswordDocument);

  const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      password: Yup.string()
        .matches(passwordRegExp, t('invalid password'))
        .max(22, t('invalid password'))
        .required(t('required')),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password')], t('passwords do not match'))
        .required(t('required')),
    }),
    onSubmit: async values => {
      await resetPassword({ variables: { password: values.password, token: token } });
    },
  });

  if (data?.resetPassword?.token) {
    Cookies.set(TOKEN, data.resetPassword.token, { expires: 1 });
    return <Redirect to="/" />;
  }

  if (loading) {
    return <LoadingDisplay />;
  }

  return (
    <>
      <Row className="h-100vh">
        <Col className="left-section p-0 d-none d-md-flex align-items-center justify-content-center">
          <div className="d-block d-lg-none">
            <Logo logoFontSize={55} typeLogo="mainLogo" />
          </div>
          <div className="d-none d-lg-block">
            <Logo logoFontSize={75} typeLogo="mainLogo" />
          </div>
        </Col>

        <Col className="right-section p-0 d-flex flex-column justify-content-center  align-items-center">
          <div className="d-block d-md-none">
            <Logo logoFontSize={30} typeLogo="mainLogo" />
          </div>

          <div className="position-relative">
            <FormLoginRegistration formik={formik} passwordUpdate />
            {error && <Snackbar text="Попробуйте еще раз" className="authorizationError" />}
          </div>
        </Col>
      </Row>
    </>
  );
};
